import React from 'react'

import "bootstrap/dist/css/bootstrap.min.css"

import './App.css';

import { BrowserRouter, Routes, Route } from "react-router-dom"
// import { BrowserRouter , Router, Route } from "react-router-dom";

import Login from "./pages/Login/Login"
import Bookings from "./pages/Bookings/Bookings";
import Upcoming from "./pages/Upcoming/Upcoming";
import Test from './Test'



function App() {

 
 


  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<Login  />} />
      <Route path="/bookings" element={<Bookings  />} />
      <Route path="/upcoming" element={<Upcoming />} />
      <Route path="/test" element={<Test />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App;
