
import React from "react";
import moment from 'moment/moment';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import DateRangeSelector from "./component/DateRangeSelector";

import './Test.css'

const Test = () => {
    return (
        <div className="container">
            <div className="row">
                <section>
                    <div className="section-title pb-2">
                        <div className="row mb-3">
                            <div className="col-12 col-xl-7 col-lg-7 col-md-7">
                                <h2 className="mb-2" >Reports</h2>
                                <p>
                                    <span className="fw-500 text-primary">{moment().format('MMMM Do YYYY, h:mm:ss a')}</span></p>
                            </div>
                            <div className="col-12 col-xl-5 col-lg-5 col-md-5">
                                <div className="row justify-content-end">
                                    <div className="col-9 col-lg-9 col-md-9 col-sm-9 pe-1 pe-xl-0 pe-lg-0 pe-md-0 mb-2">
                                        <DateRangeSelector />
                                    </div>
                                    <div className="col-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 ps-1 ps-xl-0 ps-lg-0 ps-md-0 mb-2 text-end">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>


    );
}

export default Test