import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import logo from '../../assets/images/logo.png';
import './Login.css'


const baseURL = process.env.REACT_APP_WORDPRESS_API_URL


export default function Login(props) {
  const [email, setEmail] = useState("");
  const [passw, setPassw] = useState("");
  const navigate = useNavigate();
  // const [data, setData] = useState({ data: [] });
  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState('');
  const [authenticated, setauthenticated] = useState(
    localStorage.getItem(localStorage.getItem("authenticated") || false)
  );

  useEffect(() => {
    const loggedInUser = localStorage.getItem("authenticated");
    // console.log("loggedInUser",loggedInUser)
    // console.log("authenticated",authenticated)
    if (loggedInUser) {
      setauthenticated(loggedInUser);
    }
  }, []);
  
  const handleSubmit = async (event) => {
    // Prevent page reload
    event.preventDefault();
    event.disabled = true;
    try {
      const response = await fetch(baseURL + 'login?name=' + email + '&password=' + passw, {
        method: 'GET',
        maxBodyLength: Infinity,
        headers: {
          'Accept': '*/*'
        },
      });
      if (!response.ok) {
        throw new Error(`Error! status: ${response.status}`);
        
      }
      const result = await response.json();
 
      localStorage.setItem("authenticated", true);
      localStorage.setItem("access_token", result.access_token);

      
      navigate("/bookings");
    }
    catch (err) {
      setErr(err.message);
      event.disabled = true;
    } finally {
      setIsLoading(false);
      event.disabled = true;
    }
  }
  if (authenticated === true) {
    navigate("/bookings");
  } else {
    return (
<section className="Auth d-flex align-items-center">
      <div className='container'>
 


        <div className="text-center">
          <img className='d-block mx-auto mb-4' src={logo} alt="Favourite Saloon Canada" />
        </div>

        <div className='row justify-content-center'>
          <div className='col-md-4 order-md-2 mb-4'>
          <form className="" action="" onSubmit={handleSubmit}>
              <div className="Auth-form-content">
                <h3 className="Auth-form-title">Sign In</h3>
                <div className="form-group mt-3">
                  <label>Email address</label>
                  <input
                    type="text"
                    className="form-control mt-1"
                    placeholder="Enter email"
                    name="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="form-group mt-3">
                  <label>Password</label>
                  <input
                    type="password"
                    className="form-control mt-1"
                    placeholder="Enter password"
                    name="passw"
                    id="passw"
                    value={passw}
                    onChange={(e) => setPassw(e.target.value)}
                  />
                </div>
                <div className="d-grid gap-2 mt-3">
                  <button type="submit" className="btn btn-dark">
                    Submit
                  </button>
                  {isLoading && <h2>Loading...</h2>}
                </div>
              </div>
              {err && <p className="errorMessage">{err}</p>}
            </form>

          </div>
           

          
        </div>
        

      </div>
      </section>
    
    )
  }
}