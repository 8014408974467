import React from 'react'
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Icon from 'react-bootstrap-icons';
import moment from 'moment/moment';
import Footer from '../../component/Footer'
import Loading from '../../component/Loading';

import '../../pages/Bookings/Bookings.css'


import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const baseURL = process.env.REACT_APP_WORDPRESS_API_URL


const statusTranslation = {
    "sln-b-paid": "Paid",
    "sln-b-paylater": "Pay Later",
    "sln-b-confirmed": "Confirmed",
    "sln-b-pending": "Pending"
}
export default function Upcoming(props) {
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [post, setPost] = useState(null);
    const [filteredData, setFilteredData] = useState(post);
    const [searchFlag, setSearchFlag] = useState(false);
    const [authenticated, setauthenticated] =  useState(props.userStatus || false);

 
    const [isLoading, setIsLoading] = useState(false);
    const [err, setErr] = useState('');

    const [startDatePicker, setStartDatePicker] = useState(new Date());


    if (authenticated === false || authenticated === null) {
        navigate("/")
    }


    useEffect(() => {
        const loggedInUser = localStorage.getItem("authenticated");
        if (loggedInUser) {
            setauthenticated(loggedInUser);
        } else {
            setauthenticated(false);
        }
    }, []);
    useEffect(() => {
        async function fetchData() {
            const start_date = moment().add(-30, 'd').format('YYYY-MM-DD') //2023-05-01 YYYY-MM-DD
            const end_date = moment().add(365, 'd').format('YYYY-MM-DD')
            console.log("start_date", start_date)
            console.log("end_date", end_date)
            const upcomingBooking = 'bookings/upcoming?hours=124'

            try {
                const response = await fetch(baseURL + upcomingBooking, {
                    method: 'GET',
                    maxBodyLength: Infinity,
                    headers: {
                        'Access-Token': localStorage.getItem("access_token"),
                        'Accept': 'application/json',
                    },
                });
                if (!response.ok) {
                    console.log("status", response.status)
                    if (response.status === 401) {
                        localStorage.setItem("authenticated", false);
                        localStorage.setItem("access_token", "");
                    }
                    throw new Error(`Error! status: ${response.status}`);
                }
                const result = await response.json();
                // console.log('result is: ', JSON.stringify(result, null, 4))
                setPost(result);
            }
            catch (err) {
                setErr(err.message);
                setError(err.message);

            } finally {
                setIsLoading(false);
                console.log('setIsLoading is: ', isLoading)
                console.log('err / error is: ', err)
            }
        } // end of fetchData
        fetchData();
    }, [authenticated, isLoading, err]);

    const handleSearch = (event) => {
        let value = event.target.value.toLowerCase();
        let result = [];
        result = post.items.filter((data) => {
            return data.date.search(value) !== -1 || data.customer_first_name.search(value) !== -1;
        });


        setSearchFlag(true);
        setFilteredData(result);
    }

    const handleDatePickerSearch = (v) => {
        let value = v;
        let result = [];
        result = post.items.filter((data) => {
            return data.date.search(value) !== -1 || data.customer_first_name.search(value) !== -1;
        });

        console.log("typed value = ", value)
        setSearchFlag(true);
        setFilteredData(result);
    }

    if (error) {
        return (
            <p>
                {error.message}, if you get this error,  Please contact support@ima-appweb.com
            </p>
        );
    }
    else if (!post) {
        return (
            <Loading />
            // return "Loading..."
        )
    }
    else {
        let tempArray = filteredData;
        if (searchFlag === true) {
            tempArray = filteredData
        } else {
            tempArray = post.items
        }




        return (
            <>

                <div className='container'>



                    <div className="row mt-1 mb-1">

                        <div className='col-md-4 mx-auto'>
                            <div className="col-md-12">
                                <div className="searchBar">
                                    <div className="input-group">
                                        <input className="search-control form-control border rounded-pill" type="search" onChange={(event) => handleSearch(event)} placeholder="Search.." />

                                    </div>
                                </div>

                                <div className="datePickerContainer">


                                    <DatePicker
                                        selected={startDatePicker}
                                        onChange={(date) => {
                                            setStartDatePicker(date)
                                            handleDatePickerSearch(moment(date).format('YYYY-MM-DD'))
                                        }}
                                        onSelect={(date) => setStartDatePicker(date)}
                                    />

                                </div>
                            </div>

                            <div className='col-md-12'>

                                <label className='searchText text-center'>Please type the date as seen in the screen below eg. 2023-05-28</label>
                            </div>
                        </div>

                    </div>


                    <div className='row'>
                        <div className='col-md-4 mx-auto'>

                            {
                                tempArray.map(function (postData, i) {
                                    return (
                                        <div key={i} className='col-md-12'>


                                            <div className="card bg-light mb-2" >
                                                <div className="card-header">{statusTranslation[postData.status]}</div>
                                                <div className="card-body">
                                                    <h5 className="card-title">{postData.customer_first_name + " " + postData.customer_last_name}</h5>
                                                    <p className="card-text"><Icon.CalendarDate /> {postData.date} <Icon.Alarm /> {postData.time} </p>
                                                    <p className="card-text"><Icon.Envelope /> <a href={"mailto:" + postData.customer_email} > {postData.customer_email}</a></p>
                                                    <p className="card-text"><Icon.Telephone /> <a href={"tel:" + postData.customer_phone} > {postData.customer_phone}</a></p>
                                                    {
                                                        postData.services.map(function (servicesData, j) {
                                                            return (
                                                                <div key={j + '_subchild'}>
                                                                    <p className="card-text">
                                                                        <b>Service {j + 1}:</b> <span>{servicesData.service_name} @ ${servicesData.service_price}, <b>Attendant:</b> {servicesData.assistant_name}</span>
                                                                    </p>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-4 mx-auto'>

                            <Footer />

                        </div>
                    </div>
                </div>

            </>
        );
    }};
