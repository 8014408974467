import React from 'react'
import "./Footer.css"
 
 

const Footer = () => {
 

    return (
        <div className="footer">
            


                <a href="/upcoming" className="badge badge-primary">Upcoming</a>
                <a href="/bookings" className="badge badge-secondary">All</a>
                <a href="https://favouritesalon.com/bookings/wp-admin/" className="badge badge-secondary">Dashboard</a>

            
        </div>

    )
}

export default Footer