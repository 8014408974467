import React from 'react'
import PlaceholderLoading from 'react-placeholder-loading'
 
import './Loading.css'



const Loading = () => {
  return (

    <div className='container'>
      <div className="row align-items-center loader">
        <div className="col-md-4 mx-auto">
          <div className='my-auto text-center'>
            <PlaceholderLoading shape="circle" width={80} height={80} />
          </div>
        </div>
      </div>
    </div>


 
    
  )
}

export default Loading

